.btn {
  font-size: 20px;
  text-decoration: none;

  cursor: pointer;
  border: 0;
  width: max-content;
  padding: 12px 32px;

  transition: all 0.2s ease-in;

  &-main {
    background-color: var(--new-color-1);
    color: white;

    &:hover {
      background-color: var(--new-color-2);
    }
    &:active {
      background-color: var(--new-color-3);
    }
  }

  &-round {
    border-radius: 100px;
  }

  &-arrow {
    padding: 12px 36px;
    position: relative;

    &-end {
      position: absolute;
      right: 0;
      top: -8px;

      transform: translate(50%, 2px);

      border-radius: 100px;
      padding: 30px;
      border: none;

      img {
        position: absolute;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
      }

      &:hover {
        border: 1px solid var(--new-main-2);
      }
    }
  }
}
