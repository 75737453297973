$breakpoints: (
  "xs": 500px,
  "md": 700px,
  "xl": 1200px,
);

@mixin xs {
  @media (max-width: map-get($breakpoints, "xs")) {
    @content;
  }
}

