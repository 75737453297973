.menu-btn {
  position: relative;
  left: 20px;
  z-index: 30;
  display: flex;
  align-items: center;
  width: 45px;
  height: 100%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &__burger {
    width: 30px;
    height: 4px;
    background: #fff;
    border-radius: 2px;
    transition: all 0.5s ease-in-out;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 45px;
      height: 4px;
      background: #fff;
      border-radius: 2px;
      transition: all 0.5s ease-in-out;
    }
    &::before {
      transform: translateY(-15px);
    }
    &::after {
      transform: translateY(15px);
    }
  }
}

/* ANIMATION */

.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
.menu-btn.open {
  height: 35px;
  width: 35px;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(39px, -39px);
  width: 25px;
  //width: 60%;
  height: 3px;
  box-shadow: none;
}

.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(39px, 39px);
  //width: 60%;
  width: 25px;
  height: 3px;
  box-shadow: none;
}
