.mov-access {
  position: relative;
  height: auto;
  display: flex;
  background-color: rgba(49, 63, 120, 0.9);
  &-menu {
    min-width: 33vw;
    background-color: rgba(49, 63, 120, 0.9);

    &--wrapper {
      color: white;
      display: flex;
      flex-direction: column;
    }

    &__title {
      z-index: 15;
      font-size: 3.1vh;
      border-bottom: 1px solid white;
      padding: 0 40px;
      padding-bottom: 8px;
      margin-left: 15%;
    }

    &__texts {
      height: 100vh;
      margin-top: 16px;
      flex-basis: 3;
      overflow-y: scroll;
    }

    &__buttons{
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 5%;
      &__singleButton{
        width: 30%;
        height: 10%;
      }
    }

    &__legend {
      display: flex;
      margin-top: 50px;
      justify-content: center;
      align-items: center;
      height: auto;
      border-bottom: 1px solid white;
      z-index: 10;
      @media (min-width:3000px) {
        width: 100%; 
      }

    }

    &__modal-button{
      color: white;
      margin-top: 0%;
      margin-bottom: 5%;
      margin-left: 40%;
      width: 20%;
      height: 7vh;
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: bold;
      background-color: rgba(49, 63, 120, 0.5);
      box-shadow: 15px 15px #272a6b;
      transition: box-shadow 0.5s ease-in-out;
      &:hover {
        background-color: rgba(49, 63, 120, 0.8);
        box-shadow: 0px 0px #313586;
      }
      @media (max-width:1300px){
        color:rgba(49, 63, 120, 0.0);
        background-color: rgba(49, 63, 120, 0);
        box-shadow: 0px 0px rgba(49, 63, 120, 0);
        height: 0%;
        margin-left: -50%;
        &:hover {
          background-color: rgba(49, 63, 120, 0);
          box-shadow: 0px 0px rgba(49, 63, 120, 0);
        }
      }
    }

  }
}

.video-content {
  // left: 33%;
  background-color: rgba(49, 63, 120, 0.9);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 85%;
  margin-left: 7.5%;


  
  &__button-selection-primary{
    display: inline-grid;
    row-gap: 75vh;
     z-index: -10;
     column-gap: 8vh;
    width: 83%;
  }

  .overlay {
    position: absolute;
    padding: 100px 100px 0 100px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
  }
  &__vid {
    object-fit: fill;
    position: absolute;
    top: 35px;
    left: 0;
    height: 75%;
    width: 85%;
    z-index: -5;

    border-radius: 10px;
    @media (max-width:1300px) {
      margin-top: 25px;
      width:95%
    }
    &__video{
      height: 75%;
      margin-top: 2%;
      @media (max-width:1300px) {
        margin-top: 5%;
        height: 45%;
      }
    }
    &__row-titles{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5%;
      &__col{
        width: 65%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        font-size: 2.1vh;
        font-weight: 600;
      }
    }
    &__row-charts{
      height:120%;
      justify-content:center;
      margin-left: -10%;
      width: 175%;
      z-index: 55;
      @media (max-width: 1300px) {
        width: 203%;
        margin-left: -17%;

      }
      &__col{
        margin-top:1.5vh;
        width:67%; 
        height:60%;
        @media (max-width: 1300px) {
          width: 63%;
          margin-left: 2%;
  
        }
      }
    }
    &__row-texts{
      justify-content: center;
      &__paragraph{
        margin-top: -36%;
        font-style: italic;
        font-size: 1.8vh;  
        color: #262626;
        
        text-align: center;
        @media (max-width: 1300px) {
          margin-top: -85%;
          margin-left: 1%;
          
        }
      }
    }
    &__container-bg{
      display: flex;
      flex-direction: row;
      margin-left: 5%;
      margin-top:15%; 
      @media (max-width:700px){
        margin-top:-30%; 
      }
      &__video-button-down-initial{
        color: white;
        grid-row-start: 2;
        background-color: rgba(49, 63, 120, 0.5);
        box-shadow: 3px 3px #272a6b;
        transition: box-shadow 0.5s ease-in-out;
        &:hover {
          background-color: rgba(49, 63, 120, 0.8);
          box-shadow: 0px 0px #313586;
        }
      }
      &__video-button-down{
        color: white;
        grid-row-start: 2;
        background-color: rgba(49, 63, 120, 0.5);
        box-shadow: 3px 3px #272a6b;
        transition: box-shadow 0.5s ease-in-out;
        margin-left: 2vh;
        @media (max-width:700px){

        }
        &:hover {
          background-color: rgba(49, 63, 120, 0.8);
          box-shadow: 0px 0px #313586;
        }
      }
      
    }

  }

  .titles {
    height: 10%;
    text-align: center;
    .col {
      p {
        padding: 0;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 2.3vh;
        // font-family: "Bodoni";
        font-weight: bold;
      }
    }
  }

  .charts {
    height: 60%;
  }

  .texts {
    margin-top: 2%;
    height: 26%;
    .col {
      overflow: scroll;
      p {
        //padding: 10px;
        font-size: 2vh;
        font-weight: 400;
        text-align: left;
        margin: 0 10%;
        line-height: 2.8vh;
        // border-radius: 10px;
      }
      &::-webkit-scrollbar {
        display: block;
        overflow: auto;
        // height: 2em;
        width: 10px;
        &-thumb {
          background-color: gray;
          border-radius: 5px;
        }
      }
    }
  }

  .row {
    display: flex;
    padding: 0 40px;
  }

  .col {
    width: 50%;
  }
}

.map {
  height: 100%;
  width: 100%;
  padding: 0;
  position: relative;
  margin-bottom: 50px;

  &-overlay {
    .map-button {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 64px;
      margin-bottom: 64px;
    }
    .text {
      position: absolute;

      &-1 {
        right: 0;
        margin-right: 64px;
        margin-top: 64px;
      }
      &-2 {
        left: 0;
        margin-left: 64px;
        margin-top: 64px;
      }
      &-3 {
        right: 15%;
        bottom: 35%;
      }

      .title {
        font-size: 32px;
        font-weight: 700;
        width: 320px;
        margin-bottom: 24px;
      }
      .des {
        position: absolute;
        font-size: 16px;
        font-weight: 300;
        width: 320px;
      }
    }

    .card {
      padding: 24px 32px;
      width: 240px;
      text-overflow: initial;
      position: absolute;
      font-size: 24px;
      font-weight: 300;

      &-1 {
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        margin-right: 80px;
      }
      &-2 {
        top: 50%;
        transform: translateY(-50%);
        left: 50%;
        transform: translateX(-50%);
      }
      &-3 {
        top: 25%;
        transform: translateY(-25%);
        left: 15%;
        transform: translateX(-25%);
      }
    }
  }
}

.video-container {
  display: flex;
  height: 100%;
  background-color: rgba(49, 63, 120, 0.9);
  .outContainer {
    background-color: rgba(49, 63, 120, 0.9);
    flex: 1 1 0px;
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 8vh;
    .modal-button {
      color: white;
      width: 40%;
      height: 7vh;
      z-index: 10;
      margin-top: 5%;
      right: -50%;
      top: 80vh;
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: bold;
      background-color: rgba(49, 63, 120, 0.9);
      box-shadow: 15px 15px #272a6b;
      transition: box-shadow 0.5s ease-in-out;
      &:hover {
        background-color: rgba(49, 63, 120, 0.8);
        box-shadow: 0px 0px #313586;
      }
    }

    .modal-button-down {
      color: white;
      width: 40%;
      height: 7vh;
      z-index: 10;
      margin-top: 5%;
      //position: absolute;
      right: -50%;
      top: 80vh;
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: bold;
      background-color: rgba(49, 63, 120, 0.5);
      box-shadow: 15px 15px #272a6b;
      transition: box-shadow 0.5s ease-in-out;
      &:hover {
        background-color: rgba(49, 63, 120, 0.8);
        box-shadow: 0px 0px #313586;
      }
    }
  }
}
.video-text {
  margin-top: 10vh;
  height: 100%;
  width: 33%;
  background-color: black;
  color: white;
}

.video-button-up {
  color: white;
  grid-row-start: 1;
  background-color: rgba(49, 63, 120, 0.5);
  box-shadow: 5px 5px #272a6b;
  transition: box-shadow 0.5s ease-in-out;
  &:hover {
    background-color: rgba(49, 63, 120, 0.8);
    box-shadow: 0px 0px #313586;
  }
}

.video-button-down {
  color: white;
  grid-row-start: 2;
  background-color: rgba(49, 63, 120, 0.5);
  box-shadow: 3px 3px #272a6b;
  transition: box-shadow 0.5s ease-in-out;
  &:hover {
    background-color: rgba(49, 63, 120, 0.8);
    box-shadow: 0px 0px #313586;
  }
}
