.color {
  &-main-1 {
    color: var(--color-main-1);
  }
  &-white {
    color: var(--color-white);
  }
  &-black {
    color: var(--color-black);
  }
  &-new-1 {
    color: var(--new-color-1);
  }
  &-new-2 {
    color: var(--new-color-2);
  }
}

.border {
  &-main-1 {
    border: 1px solid var(--color-main-1);
  }
  &-white {
    border: 1px solid var(--color-white);
  }
  &-black {
    border: 1px solid var(--color-black);
  }
}

.bg {
  &-main-1 {
    background-color: var(--color-main-1);
  }
  &-main-2 {
    background-color: var(--color-main-2);
  }
  &-main-3 {
    background-color: var(--color-main-3);
  }
  &-main-4 {
    background-color: var(--color-main-4);
  }
  &-gray-1 {
    background-color: var(--color-gray-1);
  }
  &-gray-2 {
    background-color: var(--color-gray-2);
  }
  &-black {
    background-color: var(--color-black);
  }
  &-white {
    background-color: var(--color-white);
  }
  &-dark {
    background-color: var(--color-dark);
  }
  &-new-1 {
    background-color: var(--new-color-1);
  }
  &-new-2 {
    background-color: var(--new-color-2);
  }
  &-new-3 {
    background-color: var(--new-color-3);
  }
  &-landing-1 {
    background-color: var(--landing-gris-1);
  }
  &-landing-2 {
    background-color: var(--landing-gris-2);
  }
}
