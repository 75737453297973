* {
  margin: 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

//Hide scrollbar all across the page

body {
  .root {
    overflow-y: visible !important;
  }
  overflow-y: hidden;
}
