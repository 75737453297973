.head {
  //font-family: "Bodoni";

  position: relative;
  height: 100%;
  width: 100%;

  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-image {
    max-height: 50vh;
    max-width: 50vw;
  }

  &-title {
    text-align: center;
    position: relative;
    // color: var(--new-color-3);

    &::first-letter {
      font-size: 200px;
      top: 20px;
      margin: 0;
      padding: 0;
    }

    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      content: "";
      transform: translateY(-40%);
      background-color: var(--new-color-3);
      // background-color: white;
      width: 100%;
      height: 70%;
      z-index: -1;
    }
    // &::before {
    //   position: absolute;
    //   top: -32px;
    //   right: -32px;
    //   content: "";

    //   padding: 16px 32px 32px 32px;

    //   border: 3px solid white;
    //   width: 100%;
    //   height: 100%;
    //   z-index: 1;
    // }
    // &::after {
    //   position: absolute;
    //   top: -8px;
    //   right: 0;
    //   content: "";

    //   padding: 16px 32px 32px 32px;

    //   border: 3px solid var(--new-color-1);
    //   width: 100%;
    //   height: 100%;
    //   z-index: 1;
    // }
  }

  &-text {
    text-align: center;
    font-family: "Bodoni";
    padding-top: 0;
  }

  &-btn {
    margin-top: 32px;
  }

  &-video {
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -5;
  }
}

.text-slide {
  font-family: "Bodoni";

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  padding-top: 40px;

  // background-color: var(--new-color-3);
  // color: white;

  background-color: white;
  color: var(--new-color-3);

  &--text {
    font-family: "Bodoni";
    margin-bottom: 40px;
    @media (max-width: 786px) {
      margin-bottom: 16px;
    }
  }
  &--texts {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 786px) {
      width: 90%;
    }
  }
}

.menu {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-evenly;

  color: white;

  transition: all 0.2s ease-in;

  @media (max-width: 786px) {
    flex-direction: column;
  }

  &-item {
    position: relative;
    height: 100%;
    width: 100%;
    font-family: "Bodoni";

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 1s ease-in;

    &:hover {
      .menu-item--hidden {
        display: flex;
        background-color: rgba(white, 1);
      }

      .menu-item-title {
        display: none;
      }
    }

    &--front {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 1s ease-in;
    }

    &-title {
      position: relative;
      font-weight: bold;
      font-size: 24px;
      padding: 12px;
      transition: all 1s ease-in;
      transform: translateY(-10px);

      &[data-background="1"] {
        &::before {
          position: absolute;
          top: 0px;
          left: 0;
          content: "";

          width: 100%;
          height: 100%;
          z-index: -1;

          background-color: var(--dark-2);
        }
      }
      &[data-background="2"] {
        &::before {
          position: absolute;
          top: 0px;
          left: 0;
          content: "";

          width: 100%;
          height: 100%;
          z-index: -1;

          background-color: var(--landing-gris-2);
        }
      }
    }

    &--hidden {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      color: var(--new-color-3);

      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 100%;
      width: 100%;

      transition: all 1s ease-in;

      p {
        font-family: "Bodoni" !important;

        width: 70%;
        height: 45%;
      }
    }
  }
}
