.pob-change {
  position: relative;

  &-menu {
    width: 100%;
    height: 100%;
    background-color: var(--general-blue);

    [type="radio"] {
      visibility: hidden;
      width: 0px;
    }

    &--wrapper {
      color: white;
      display: flex;
      flex-direction: column;
    }

    &__title {
      font-size: 3.1vh;
      border-bottom: 1px solid white;
      font-family: "Bodoni";
      padding: 0 40px;
      padding-bottom: 8px;
    }

    &__texts {
      height: 100vh;
      margin-top: 16px;
      flex-basis: 3;
      font-family: "Bodoni";
      overflow-y: scroll;
    }

    &__controls {
      display: flex;
      padding: 0 40px;

      &--pob-selector {
        flex: 1;

        input {
          padding-bottom: 8px;
        }

        &-btn {
          min-width: max-content;
          padding: 4px 8px;
          border: 1px solid white;
          border-radius: 8px;
        }
        &-active {
          background-color: rgba(74, 110, 255, 0.9);
        }
      }

      &--date-selector {
        flex: 1;
      }
      padding-bottom: 16px;
      border-bottom: 1px solid white;
    }
  }

  .legend {
    position: absolute;
    left: 33vw + 5vw;
    bottom: 24px;
    z-index: 11;

    height: max-content;
    width: max-content;
    padding: 8px 12px;

    background-color: rgba(60, 61, 66, 0.7);
    color: white;

    font-size: 12px;

    @media (min-width: 1536px) {
      padding: 12px 8px;
      font-size: 10px;
    }
    @media (max-width: 1300px) {
      padding: 12px 8px;
      font-size: 10px;
    }
    @media (max-width: 786px) {
      left: 160vw + 5vw;
      bottom: 24px;
    }

    @media (max-width: 576px) {
      left: 65%;
      bottom: 48px;
    }

    &-flex {
      display: flex;
    }

    &-div {
      margin: 0 12px;
      @media (min-width: 1536px) {
        margin: 0 6px;
      }
      @media (max-width: 1300px) {
        margin: 0 6px;
      }
    }

    &-title {
      font-size: 16px;
      @media (min-width: 1536px) {
        font-size: 12px;
      }
      @media (max-width: 1300px) {
        font-size: 12px;
      }
    }

    &-grid {
      display: flex;
      flex-direction: column;
      margin: 16px 0;

      &--row {
        display: flex;

        &-color {
          height: 16px;
          width: 24px;
          margin-right: 8px;
          @media (min-width: 1536px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
          @media (max-width: 1300px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
        }
      }
    }

    &-transport {
      margin-top: 16px;

      &--row {
        display: flex;

        &-color {
          height: 16px;
          width: 24px;
          margin-right: 4px;
          @media (min-width: 1536px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
          @media (max-width: 1300px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
        }
      }
    }
  }
}

.pob-map__btn {
  position: relative;
  margin: 8px 0;
  padding: 4px 8px;

  border: 1px solid white;
  border-radius: 50px;
  overflow: hidden;
  width: 148px;

  @media (min-width: 1536px) {
    min-width: min-content;
  }
  @media (max-width: 1300px) {
    min-width: min-content;
  }

  &:hover {
    cursor: pointer;
  }

  &-active {
    background-color: rgb(16, 24, 65);
  }

  &-end {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -50%);

    border: none;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-left: 1px solid white;

    padding: 16px;

    &-1 {
      background-color: #b9cf54;
    }
    &-2 {
      background-color: #63bfeb;
    }
    &-3 {
      background-color: #e16338;
    }
    &-4 {
      background-color: #f9d047;
    }

    img {
      position: absolute;
      width: 20px;
      left: 50%;
      top: 50%;

      transform: translate(-50%, -50%);
    }

    &:hover {
      border: 1px solid var(--new-main-2);
    }
  }
}

.mapboxgl-control-container {
  display: none !important;
}

.mapboxgl-canvas-container {
  height: 100vh;
  width: 100%;
  z-index: 20;
}
