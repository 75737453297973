.job-hex {
  position: relative;
  height: auto;
  display: flex;

  &-menu {
    min-width: 33vw;
    background-color: rgba(49, 63, 120, 0.9);
    
    &--wrapper {
      color: white;
      display: flex;
      flex-direction: column;
      
    }

    &__title {
      z-index: 15;
      font-size: 3.1vh;
      border-bottom: 1px solid white;
      font-family: "Bodoni";
      padding: 0 40px;
      padding-bottom: 8px;
      margin-left: 15%;
    }

    &__texts {
      height: 100vh;
      margin-top: 16px;
      flex-basis: 3;
      font-family: "Bodoni";
      overflow-y: scroll;
      
    }

    .chart-container {
      height: 500px;
      width: 90%;
      margin: auto;
    }

    &__controls {
      display: flex;

      justify-content: center;
      align-items: center;
      height: 150px;
      border-bottom: 1px solid white;
      z-index: 10;
      @media (max-width: 1200px){
        margin-left: 150%;
        margin-bottom: -40%;
      }
      &__controllsOuterContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%;
        
        float: right;
        @media (max-width: 1200px){
          background-color: rgba(49, 63, 120, 0.9);
          margin-top: 144%;
          height: 16%;
          
        }
        
      }
      &__zoomControlls {
        height: 85px;
        padding-top: 5px;
        width: 45px;
        border-radius: 15px;
        float: right;
        right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        @media (max-width: 1200px) {
          width: 30%;
          float: none;
          left: 52%;
        }
      }
      &__controlls {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20%;
        margin-left: auto;
        margin-right: auto;
        height: 90px;
        position: relative;
        
        @media (max-width: 1200px) {
          float: none;
          width: 80%;
          margin-left: 18%;
        }
      }
      &__controlsBox{
        display:flex; 
        flex-direction:column;
        &__controllsContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          background: none;
        }
        &__controlIcon {
          color: white;
          font-size: 40;
        }
        &__sourceText {
          padding-top: 5%;
          color: white;
          text-align: justify;
          font-size: 0.7rem;
          position: relative;
          top: -15px;
          @media (max-width: 1200px) {
            padding-top: 10%;
          }
        }
      }
      
/*       .legend {
        width: 100%;
        height: 20px;
      }
      .legendInnerContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: none;
        width: 100%;
        color: white;
        font-size: 0.7rem;
      } */
    }
  }

  .legend {
    position: absolute;
    left: 33vw + 5vw;
    bottom: 24px;
    z-index: 11;

    height: max-content;
    width: max-content;
    padding: 8px 12px;

    background-color: rgba(60, 61, 66, 0.7);
    color: white;

    font-size: 12px;

    @media (min-width: 1536px) {
      padding: 12px 8px;
      font-size: 10px;
    }
    @media (max-width: 1300px) {
      padding: 12px 8px;
      font-size: 10px;
    }
    @media (max-width: 786px) {
      left: 160vw + 5vw;
      bottom: 24px;
    }

    @media (max-width: 576px) {
      left: 65%;
      bottom: 48px;
    }

    &-flex {
      display: flex;
    }

    &-div {
      margin: 0 12px;
      @media (min-width: 1536px) {
        margin: 0 6px;
      }
      @media (max-width: 1300px) {
        margin: 0 6px;
      }
    }

    &-title {
      font-size: 16px;
      @media (max-width: 1300px) {
        font-size: 12px;
      }

    }

    &-grid {
      display: flex;
      flex-direction: column;
      margin: 16px 0;

      &--row {
        display: flex;

        &-color {
          height: 16px;
          width: 24px;
          margin-right: 8px;
          @media (min-width: 1536px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
          @media (max-width: 1300px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
        }
      }
    }

    &-transport {
      margin-top: 16px;

      &--row {
        display: flex;

        &-color {
          height: 16px;
          width: 24px;
          margin-right: 4px;
          @media (min-width: 1536px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
          @media (max-width: 1300px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
        }
      }
    }
  }
}

.pob-map__btn {
  position: relative;
  margin: 8px 0;
  padding: 4px 8px;

  border: 1px solid white;
  border-radius: 50px;
  overflow: hidden;
  width: 148px;

  @media (min-width: 1536px) {
    min-width: min-content;
  }
  @media (max-width: 1300px) {
    min-width: min-content;
  }

  &:hover {
    cursor: pointer;
  }

  &-active {
    background-color: rgba(49, 63, 120, 0.9);
  }

  &-end {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -50%);

    border: none;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-left: 1px solid white;

    padding: 16px;

    &-1 {
      background-color: #b9cf54;
    }
    &-2 {
      background-color: #63bfeb;
    }
    &-3 {
      background-color: #e16338;
    }
    &-4 {
      background-color: #f9d047;
    }

    img {
      position: absolute;
      width: 20px;
      left: 50%;
      top: 50%;

      transform: translate(-50%, -50%);
    }

    &:hover {
      border: 1px solid var(--new-main-2);
    }
  }
}

.mapboxgl-control-container {
  display: none !important;
}

.mapboxgl-canvas-container {
  height: 100vh;
  width: 100%;
  z-index: 20;
}

.section {
  height: 100vh;
  position: relative;
}

.mapContainer {
  position: relative;
}