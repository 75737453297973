.side-page-indicator {
  position: fixed;
  left: 2.5%;
  bottom: 2.5%;
  max-height: 210px;
  display: flex;
  flex-direction: column;
  min-width: 80px;

  .side-page-name,
  .side-page-num {
    color: white;
    margin: 5px;
  }

  .side-page-name {
    position: relative;

    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
    align-self: flex-end;
  }

  .side-page-num {
    font-size: 3rem;
    font-weight: 600;
  }
}
