/* Load Fonts*/

// Regular
@font-face {
    font-family: "Charis SIL";
    src: url('./fonts/charis_sil/CharisSILR.ttf');
    font-weight: normal;
    font-style: normal;
}

// Bold
@font-face {
    font-family: "Charis SIL";
    src: url('./fonts/charis_sil/CharisSILB.ttf');
    font-weight: bold;
    font-style: normal;
}

// Italic-Bold
@font-face {
    font-family: "Charis SIL";
    src: url('./fonts/charis_sil/CharisSILBI.ttf');
    font-weight: bold;
    font-style: italic;
}

// General

h1 {
    font-family: "Charis SIL";
    font-weight: bold;
    font-style: normal;
}

h3 {
    font-family: "Charis SIL";
    font-weight: bold;
    font-style: italic;
}

h4 {
    font-family: "Charis SIL";
    font-weight: bold;
    font-style: italic;
}

p {
    font-family: "Charis SIL";
    font-style: normal;
    font-weight: normal;   
}

button {
    font-family: "Charis SIL";
    font-style: normal;
    font-weight: normal; 
}

// Landing Page
.head {
    font-family: "Charis SIL";
    font-weight: bold;
    font-style: normal;
}

.menu-item {
    font-family: "Charis SIL";  
}

// Pob maps
.pob {
    &-hex {
        &-menu {
            &__title {
                font-family: "Charis SIL";
                font-weight: bold; 
            }

            &__text {
                font-family: "Charis SIL";
                font-style: normal;
            }

            &__controls__controlsBox__sourceText {
                font-family: "Charis SIL";
                font-style: normal;
                font-weight: normal;
            }
        }
    }

    &-change{
        &-menu{
            &__title {
                font-family: "Charis SIL";
                font-weight: bold; 
            }
            &__controls {
                &--date-selector {
                    font-family: "Charis SIL";
                    font-weight: normal; 
                }
            }
        }
    }

    &-map {
        &__btn {
            font-family: "Charis SIL";
            font-weight: normal;
        }
    }
}

.job-hex {
    &-menu {
        &__title {
            font-family: "Charis SIL";
            font-weight: bold; 
        }

        &__text {
            font-family: "Charis SIL";
            font-style: normal;
        }

        &__controls__controlsBox__sourceText {
            font-family: "Charis SIL";
            font-style: normal;
            font-weight: normal;
        }
    }
}

.bar {
    &-text {
        &-item {
            font-family: "Charis SIL";
            font-style: italic;
            font-weight: bold;
        }
    }
}

#video-legend .title {
    font-family: "Charis SIL";
    font-style: italic;
    font-weight: bold;
}

.video {
    &-content {
        &__button {
            &-selection {
                &-primary {
                    button {
                        font-family: "Charis SIL";
                        font-style: normal;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}

.mov-access-menu__modal-button {
    font-family: "Charis SIL";
    font-style: italic;
    font-weight: bold;
}

.dem {
    &-change {
        &-menu {
            &__controls {
                &--pob-selector {
                    h3 {
                        font-family: "Charis SIL";
                        font-style: italic;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.map-btn-content {
    font-family: "Charis SIL";
    font-style: normal;
    font-weight: normal; 
}

text {
    font-family: "Charis SIL";
    font-style: normal;
    font-weight: normal; 
}

.MuiButton-root {
    font-family: "Charis SIL";
    font-style: normal;
    font-weight: normal; 
}


// subcentros1-menu__controls
