.pav-parks {
  position: relative;
  height: auto;
  display: flex;

  &-menu {
    min-width: 33vw;
    background-color: rgba(49, 63, 120, 0.9);

    &--wrapper {
      color: white;
      display: flex;
      flex-direction: column;
    }

    &__title {
      z-index: 15;
      font-size: 3.1vh;
      border-bottom: 1px solid white;
      font-family: "Bodoni";
      padding: 0 40px;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-left: 7%;
    }

    &__texts {
      height: 100vh;
      margin-top: 16px;
      flex-basis: 3;
      font-family: "Bodoni";
      overflow-y: scroll;
    }
    &__legend {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      border-bottom: 1px solid white;
      z-index: 10;
      margin-top: 10%;

      @media (min-width: 1536px) {
      padding: 12px 8px;
      font-size: 10px;
    }
    @media (max-width: 1300px) {
      padding: 12px 8px;
      font-size: 10px;
    }
    @media (max-width: 786px) {
      left: 160vw + 5vw;
      bottom: 24px;
    }

    @media (max-width: 576px) {
      left: 65%;
      bottom: 48px;
    }

    &-flex {
      display: flex;
    }

    }

   

  }
}
