.content {
  height: 100%;
  width: 100%;
  @media (max-width: 786px) {
    padding: 0;
  }
}

.content-migrar {
  height: 100%;
  width: 100%;
}

.team-content {
  position: absolute;
  left: -8%;
  height: 100%;
  width: 100%;
  padding: 100px 100px 0 100px;
}

.video-content {
  flex: 2 1 0px;
  height: 92%;
  width: 67%;
  position: relative;
  top: 7vh;
  transform: translate(2.5%, 3.5%);
  overflow: hidden;

  .overlay {
    position: absolute;
    padding: 100px 100px 0 100px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
  }
  &--vid {
    object-fit: fill;
    position: absolute;
    top: 35px;
    left: 0;
    height: 88%;
    width: 95%;
    z-index: -5;
    border-radius: 10px;
  }

  .titles {
    height: 10%;
    text-align: center;
    .col {
      p {
        padding: 0;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 2.3vh;
        // font-family: "Bodoni";
        font-weight: bold;
      }
    }
  }

  .charts {
    height: 60%;
  }

  .texts {
    margin-top: 2%;
    height: 26%;
    .col {
      overflow: scroll;
      p {
        //padding: 10px;
        font-size: 2vh;
        font-weight: 400;
        text-align: left;
        margin: 0 10%;
        line-height: 2.8vh;
        // border-radius: 10px;
      }
      &::-webkit-scrollbar {
        display: block;
        overflow: auto;
        // height: 2em;
        width: 10px;
        &-thumb {
          background-color: gray;
          border-radius: 5px;
        }
      }
    }
  }

  .row {
    display: flex;
    padding: 0 40px;
  }

  .col {
    width: 50%;
  }
}

.map {
  height: 100%;
  width: 100%;
  padding: 0;
  position: relative;
  margin-bottom: 50px;

  &-overlay {
    .map-button {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 64px;
      margin-bottom: 64px;
    }
    .text {
      position: absolute;

      &-1 {
        right: 0;
        margin-right: 64px;
        margin-top: 64px;
      }
      &-2 {
        left: 0;
        margin-left: 64px;
        margin-top: 64px;
      }
      &-3 {
        right: 15%;
        bottom: 35%;
      }

      .title {
        font-size: 32px;
        font-weight: 700;
        width: 320px;
        margin-bottom: 24px;
      }
      .des {
        position: absolute;
        font-size: 16px;
        font-weight: 300;
        width: 320px;
      }
    }

    .card {
      padding: 24px 32px;
      width: 240px;
      text-overflow: initial;
      position: absolute;
      font-size: 24px;
      font-weight: 300;

      &-1 {
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        margin-right: 80px;
      }
      &-2 {
        top: 50%;
        transform: translateY(-50%);
        left: 50%;
        transform: translateX(-50%);
      }
      &-3 {
        top: 25%;
        transform: translateY(-25%);
        left: 15%;
        transform: translateX(-25%);
      }
    }
  }
}

.video-container {
  display: flex;
  height: 100%;
  background-color: #455187;
  .outContainer {
    background-color: rgba(49, 63, 120, 0.9);
    flex: 1 1 0px;
    position: relative;
    width: 100%;
    height: 100%;
    // margin-top: 8vh;
    .modal-button {
      color: white;
      width: 40%;
      height: 7vh;
      z-index: 10;
      margin-top: 5%;
      right: -50%;
      top: 80vh;
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: bold;
      background-color: rgba(49, 63, 120, 0.5);
      box-shadow: 15px 15px #272a6b;
      transition: box-shadow 0.5s ease-in-out;
      &:hover {
        background-color: rgba(49, 63, 120, 0.8);
        box-shadow: 0px 0px #313586;
      }
    }

    .modal-button-down {
      color: white;
      width: 40%;
      height: 7vh;
      z-index: 10;
      margin-top: 5%;
      //position: absolute;
      right: -50%;
      top: 80vh;
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: bold;
      background-color: rgba(49, 63, 120, 0.5);
      box-shadow: 15px 15px #272a6b;
      transition: box-shadow 0.5s ease-in-out;
      &:hover {
        background-color: rgba(49, 63, 120, 0.8);
        box-shadow: 0px 0px #313586;
      }
    }
    .container {
      // overflow-y: scroll;
      position: relative;
      height: 100%;
      padding: "0px 0px";
      .innerContainer {
        margin-top: 8vh;
        width: 100%;
        height: 100%;
        // overflow-y: scroll;
        .section {
          backdrop-filter: blur(15px);
          padding: 35px;
          overflow-y: scroll;
          // padding-bottom: 70%;
          // background-color: pink;
          .lineaTitulo {
            margin-left: 3vw;
            margin-bottom: -3.5vh;
            border-left: 2px solid white;
            height: 6vh;
          }
          .titContainer {
            position: relative;
            border-radius: 1px;
            margin-left: 6%;
            margin-right: 6%;
            height: 8.5vh;
            .mapTitle {
              position: relative;
              left: 50%;
              // top: 5vh;
              transform: translate(-50%, -50%);
              color: white;
              font-size: 3.1vh;
              font-weight: bold;
              padding: 0;
              margin-top: 5%;
              margin-bottom: -3vh;
              text-align: left;
              margin-left: 5%;
              margin-right: 5%;
              //text-transform: capitalize;
            }
            .line {
              height: 1px;
              width: 60%;
              background-color: white;
              position: absolute;
              bottom: 0px;
              right: 0px;
            }
          }
          .item-container {
            overflow-y: scroll;
            padding-bottom: 50px;
            height: 60vh;
            .item {
              border-radius: 15px;
              margin-bottom: 20px;
              color: #fff;
              & p {
                font-size: 2vh;
                font-weight: 400;
                text-align: left;
                margin-left: 20%;
                margin-right: 15%;
              }
              .subtitle {
                font-size: 2.4vh;
                font-weight: bold;
                margin-bottom: 15px;
                text-align: left;
                margin-left: 10%;
                margin-right: 10%;
                border-left: 2px solid white;
                padding: 1.5vw;
                margin-top: -0.75vh;
                width: 20vw;
              }
            }
            &.special {
              margin-top: 0.75vh;
              height: 47vh;
              // background-color: pink;
            }
          }
        }
      }
    }
  }
}

.video-text {
  margin-top: 10vh;
  height: 100%;
  width: 33%;
  background-color: black;
  color: white;
}

.video-button-up {
  color: white;
  grid-row-start: 1;
  background-color: rgba(49, 63, 120, 0.5);
  box-shadow: 5px 5px #272a6b;
  transition: box-shadow 0.5s ease-in-out;
  &:hover {
    background-color: rgba(49, 63, 120, 0.8);
    box-shadow: 0px 0px #313586;
  }
}

.video-button-down {
  color: white;
  grid-row-start: 2;
  background-color: rgba(49, 63, 120, 0.5);
  box-shadow: 3px 3px #272a6b;
  transition: box-shadow 0.5s ease-in-out;
  &:hover {
    background-color: rgba(49, 63, 120, 0.8);
    box-shadow: 0px 0px #313586;
  }
}
