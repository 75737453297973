@import "/src/styles/breakpoints.scss";

.text {
  font-family: "Bodoni MT", Didot, "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif; font-style: normal; font-variant: normal;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 80%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .description-header {
    flex: 0 0 10%;
    display: flex;
    align-items: end;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    width:100%;
    h1 {
      margin: 20px auto;
      padding: 20px 20px;
      text-align: center;
      background-color: rgba(white, 0.05);
      font-size: 4vh;

      @include xs {
        font-size: 25px;
      }
    }
    h2 {
      width: 40%;
      margin: 20px auto;
      font-size: 3vh;

      @include xs {
        width: 70%;
        font-size: 20px;
      }
    }
  }
  .container {
    //flex: 0 0 90px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: column wrap;
    //height: 60%;
    height: 40%;
    width: 40%;
    position: relative;
    
    @include xs {
      width: 80%;
      height: 60%;
    } 

    .description-container {
      flex: 0 0 80%;
      overflow: scroll;

      .description {
        //padding: 60px 30px;
        //padding: 40px 30px;
        padding: 30px 30px; // Change this if you change description-container's height
        p {
          text-align: left;
          line-height: 3vh;
          font-size: 2.3vh;
          letter-spacing: 2px;

          @include xs {
            font-size: 15px;
            line-height: 20px;
            letter-spacing: normal;
          }
        }

      }
    }

    .before-bar {
      flex: 0 0 1px;// Width controlled with javascript
      position: relative;
      // height: 2px;
      background-color: white;
    }

    .after-bar {
      flex: 0 0 1px;
      position: relative;
      // height: 2px; // Witdh controlled with javascript
      background-color: white;
    }
  }
}


