.subcentros1 {
  &-menu {
    min-width: 33vw;
    background-color: rgba(49, 63, 120, 0.9);

    &--wrapper {
      color: white;
      display: flex;
      flex-direction: column;
    }

    &__title {
      z-index: 15;
      font-size: 3.1vh;
      border-bottom: 1px solid white;
      //font-family: "Bodoni";
      padding: 0 40px;
      padding-bottom: 8px;
    }

    &__texts {
      height: 100vh;
      margin-top: 16px;
      flex-basis: 3;
      //font-family: "Bodoni";
      overflow-y: scroll;
    }

    .chart-container {
      height: 500px;
      width: 90%;
      margin: auto;
    }

    &__controls {
      display: flex;
      padding: 0 40px;
      justify-content: center;
      align-items: center;

      &--pob-selector {
        flex: 1;

        input {
          padding-bottom: 8px;
        }

        &-btn {
          min-width: max-content;
          padding: 4px 8px;
          border: 1px solid white;
          border-radius: 8px;
        }
        &-active {
          background-color: rgba(74, 110, 255, 0.9);
        }
      }

      &--date-selector {
        flex: 1;
      }
      height: 150px;
      border-bottom: 1px solid white;
      @media (max-width: 1300px) {
        border-bottom: none;
        height: 15vh;
      }
    }
  }
}

