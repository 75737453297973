#video-legend {
  width: 100%;
  color: white;
  /* backdrop-filter: blur(40px); */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 2.5%;
  .title {
    margin-top: -2.5vh;
    flex: 1 1 100%;
    text-align: center;
    /* font-size: 1.5rem; */
    /* font-weight: bold; */
  }
  .row {
    flex: 1 1 34%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 4%;
    .bullet {
      margin: 0 10px;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      border: 2px solid rgba(255, 255, 255, 0.6);
    }
  }
}
