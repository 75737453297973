.header {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  height: var(--header-space);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(50, 50, 50, 0.6);
  backdrop-filter: blur(40px);
  padding: var(--header-padding) 16px;
  box-sizing: border-box;

  .logos {
    height: 100%;
    margin-right: 2.5%;
    img {
      height: 100%;
      width: auto;
    }
    @media (max-width: 786px) {
      height: 70%;
    }
  }
}

.header-space {
  height: var(--header-total);
}
