.graph-content {
  flex: 2 1 0px;
  height: 85%;
  width: 90%;
  position: relative;
  // left: 33%;
  top: 8vh;
  transform: translate(2.5%, 3.5%);
  overflow: hidden;

  .overlay {
    position: absolute;
    padding: 100px 100px 0 100px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
  }

  .titles {
    height: 10%;
    text-align: center;
    .col {
      p {
        padding: 0;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 2.3vh;
        // font-family: "Bodoni";
        font-weight: bold;
      }
    }
  }

  .texts {

    height: 26%;
    .col {
      overflow: scroll;
      p {
        //padding: 10px;
        font-size: 2vh;
        font-weight: 400;
        text-align: left;
        margin: 0 10%;
        line-height: 2.8vh;
        // border-radius: 10px;
      }
      &::-webkit-scrollbar {
        display: block;
        overflow: auto;
        // height: 2em;
        width: 10px;
        &-thumb {
          background-color: gray;
          border-radius: 5px;
        }
      }
    }
  }
  .charts {
    
      height: 60%;
  }
  .row {
    display: flex;
    padding: 0 40px;
  }

  .col {
    width: 100%;
  }
}

