.sidebar {
  position: fixed;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-item {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-icon {
      margin: 10px 0;
      border-radius: 100px;
      width: 16px;
      height: 16px;

      &:hover {
        background-color: #313586;
        cursor: pointer;
      }
    }

    &:not(:last-child) {
      .vertical-line {
        height: 30px;
        width: 2px;
        background-color: var(--color-black);
      }
    }
  }
}

.sidebar-test {
  height: 100vh;
  width: 32px;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: black;

  @media (max-width: 786px) {
    position: fixed;
    top: 100%;
    left: 0;
    width: 100vw;
    height: 4vh;
    flex-direction: row;
    transform: translateY(-100%);
  }

  &--pill {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
    border-top: 1px solid gray;
    transition: all 0.2s ease-in;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;

    @media (max-width: 786px) {
      flex-direction: row;
    }

    cursor: pointer;
    color: white;

    &__top {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 786px) {
        transform: rotateZ(-90deg);
      }

      &:hover {
        background-color: white;
        color: var(--color-main-1);
      }
    }

    &__mid {
      flex: 2;
    }
    &__low {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 786px) {
        transform: rotateZ(-90deg);
      }

      &:hover {
        background-color: white;
        color: var(--color-main-1);
      }
    }

    &:hover {
      background-color: var(--color-main-3);
    }

    &__active {
      background-color: var(--color-main-1) !important;
    }

    .pill-title {
      position: absolute;
      float: left;
      z-index: -1;
      transform: translateX(-100%);

      width: max-content;
      height: 100%;
      border: 1px solid #efefef;
      background-color: rgba(50, 50, 50, 0.8);
      padding: 0 8px;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      visibility: hidden;
      opacity: 0;

      transition: visibility 0s, opacity 0.2s ease-in;
    }

    &:hover {
      .pill-title {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
