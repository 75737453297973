.head {
  &-1 {
    font-size: 56px;
  }
}

.subtitle {
  font-size: 20px;
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.title {
  &-1 {
    text-transform: uppercase;
    width: min-content;
    letter-spacing: 32px;
    font-size: 80px;
    font-weight: bold;
  }
}

.sub {
  &-1 {
    letter-spacing: 2px;
    font-size: 48px;
    font-weight: bold;

    @media (max-width: 786px) {
      letter-spacing: 2px;
      font-size: 28px;
    }
  }

  &-2 {
    letter-spacing: 3px;
    font-size: 40px;
    font-weight: bold;

    @media (max-width: 786px) {
      font-size: 24px;
    }
  }

  &-3 {
    letter-spacing: 2px;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.3;

    @media (max-width: 786px) {
      font-size: 20px;
    }
  }

  &-4 {
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
  }
}
