.frame {
  height: 100vh;
  // background-color: pink;
}

.bg-image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}
