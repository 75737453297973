.bar-text {
  overflow-y: hidden;
  position: relative;
  height: 100%;

  &--in {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  &--content {
    backdrop-filter: blur(15px);
    padding-bottom: 100px;

    @media (min-width: 1536px) {
      padding-bottom: 80px;
    }
    @media (max-width: 1300px) {
      padding-bottom: 60px;
    }

    &-line {
      margin-left: 2vw;
      margin-bottom: -3.5vh;
      border-left: 2px solid white;
      height: 6vh;
    }
    &-container {
      position: relative;
      border-radius: 1px;
      margin-left: 6%;
      margin-right: 6%;
    }
  }
  &-title {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 3.1vh;
    font-weight: bold;
    padding: 0;
    margin-top: 5%;
    margin-bottom: 10%;
    text-align: left;
    margin-left: 5%;
    margin-right: 5%;
  }
  &-line {
    height: 1px;
    width: 60%;
    background-color: white;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  &-item {
    border-radius: 15px;
    margin-bottom: 20px;
    color: #fff;
    &::-webkit-scrollbar {
      display: flex !important;
    }
    & p {
      font-size: 2vh;
      font-weight: 400;
      text-align: left;
      margin-left: 20%;
      margin-right: 15%;

      @media (max-width: 786px) {
        margin: 0 10%;
      }
    }
  }

  &-subtitle {
    font-size: 2.4vh;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
    border-left: 2px solid white;
    padding: 24px;
    margin-top: -0.75vh;
    width: 20vw;

    @media (max-width: 786px) {
      width: 80%;
    }
  }

  &-subtitleSub {
    font-size: 2.4vh;
    font-weight: bold;
    text-align: center;
    margin-left: 2.2%;
    padding: 10px;
    width: 60vw;
    background-color:rgb(105,105,105,0.8);
    z-index: 1000;
    top: 16vh;
    position: absolute;

    @media (max-width: 786px) {
      width: 80%;
    }
  }
}
