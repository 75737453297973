.test-frame-wrapper {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-color: var(--general-blue);
}

.test-frame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  transition: all 0.4s ease-in;

  @media (max-width: 786px) {
    width: 200%;
  }

  &__side {
    width: 33%;
    height: 100%;
    padding-top: var(--header-space);
    background-color: var(--general-blue);

    @media (max-width: 786px) {
      width: 50%;
    }
  }
  &__map {
    width: 67%;
    height: 100%;
    background-color: var(--general-blue);

    @media (max-width: 786px) {
      width: 50%;
    }
  }

  &_btn {
    position: absolute;
    display: none;
    z-index: 1000;

    @media (max-width: 786px) {
      text-decoration: none;
      border: none;
      display: block;
      background-color: white;
      padding: 4px 8px;
    }

    &--next {
      top: 90%;
      left: 45%;
      transform: translate(-50%, -50%);
    }
    &--back {
      top: 90%;
      right: 45%;
      transform: translate(50%, -50%);
    }
  }

  @media (max-width: 786px) {
    &--slide {
      transform: translateX(-50%);
    }
  }
}
