.myPopup2 .mapboxgl-popup-content {
    /*background-color: #f9f9f9;*/
    background-color: #1C2833;
    /*color: #000000;*/
    color: #808B96;
    border-radius: 0px;
    font-family: Arial;
    /*box-shadow: 3px 3px 2px rgba(0,0,0,0.6);*/
    font-size: larger;
}
.myPopup2 .mapboxgl-popup-tip {
    border-top-color:#273746;
}