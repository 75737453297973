.people {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-top: 1vh;
  

  .person {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0 24px;

    .area {
      font-size: 24px;
      margin-bottom: 8px;
      font-weight: bold;
      background-color: rgba(0, 30, 108, 0.35) ;
      padding: 12px;
      border-radius: 8px;
    }
    .name {
      font-size: 16px;
    }
    .description {
      font-size: 12px;
    }
  }
}