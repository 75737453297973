.income {
  &-map-container {
    height: 100vh;
    width: 100%;
  }

  &-menu {
    min-width: 33vw;
    background-color: rgba(49, 63, 120, 0.9);

    &--wrapper {
      color: white;
      display: flex;
      flex-direction: column;
    }

    &__title {
      z-index: 15;
      font-size: 3.1vh;
      border-bottom: 1px solid white;
      font-family: "Bodoni";
      padding: 0 40px;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &__texts {
      height: 100vh;
      margin-top: 16px;
      flex-basis: 3;
      font-family: "Bodoni";
      overflow-y: scroll;
    }

    &__controls {
      padding: 0 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .quintil-selector {
        width: 100%;
        fieldset {
          border: none;
          display: flex;

          legend {
            flex: 0 0 100%;
            text-align: center;
          }

          div {
            flex: 1 1 0;
          }

          label {
            margin-left: 8px;
          }
        }
      }
      padding-bottom: 16px;
      border-bottom: 1px solid white;
    }
  }
  .legend {
    position: absolute;
    left: 33vw + 5vw;
    bottom: 24px;
    z-index: 11;

    height: max-content;
    width: max-content;
    padding: 8px 12px;

    background-color: rgba(60, 61, 66, 0.7);
    color: white;

    display: flex;
    font-size: 12px;

    @media (min-width: 1536px) {
      padding: 12px 8px;
      font-size: 10px;
    }
    @media (max-width: 1300px) {
      padding: 12px 8px;
      font-size: 10px;
    }

    &-div {
      margin: 0 12px;
      @media (min-width: 1536px) {
        margin: 0 6px;
      }
      @media (max-width: 1300px) {
        margin: 0 6px;
      }
    }

    &-title {
      font-size: 16px;
      @media (min-width: 1536px) {
        font-size: 12px;
      }
      @media (max-width: 1300px) {
        font-size: 12px;
      }
    }

    &-grid {
      display: flex;
      flex-direction: column;
      margin: 16px 0;

      &--row {
        display: flex;

        &-color {
          height: 16px;
          width: 24px;
          margin-right: 8px;
          @media (min-width: 1536px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
          @media (max-width: 1300px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
        }
      }
    }

    &-transport {
      margin-top: 16px;

      &--row {
        display: flex;

        &-color {
          height: 16px;
          width: 24px;
          margin-right: 4px;
          @media (min-width: 1536px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
          @media (max-width: 1300px) {
            height: 12px;
            width: 20px;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
