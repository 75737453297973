.nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  left: -300px;
  top: 0px;
  width: 300px;
  height: 100vh;
  background-color: rgba(var(--black), 0.9);
  transition-property: left;
  transition-duration: 0.5s;

  &-item {
    width: 80%;
    height: 15%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    .container {
      .text {
        display: inline-block;
        text-align: left;
        color: rgba(var(--white), 0.4);
        font-size: 24px;
        transition-property: all;
        transition-duration: 0.5s;
        font-weight: 500;
      }
      img {
        position: absolute;
        right: 0;
        bottom: 50%;
        height: 40px;
        width: 40px;
        transition-property: all;
        transition-duration: 0.5s;
      }
    }

    &:hover {
      .container {
        .text {
          font-size: 28px;
          color: rgba(var(--white), 1);
        }
        img {
          height: 45px;
          width: 45px;
        }
      }
    }

    &::after {
      content: "";
      height: 1px;
      width: 100%;
      background-color: rgba(white, 0.2);
      margin-top: 40px;
    }

    &-active {
      border-radius: 10px;
      .container {
        .text {
          color: rgba(var(--white), 1);
        }
        img {
          height: 50px;
          width: 50px;
        }
      }

      &::after {
        background-color: rgba(white, 0.5);
      }
    }
  }

  .back-arrow {
    width: 25%;
    height: auto;
    position: relative;
    left: 75%;
    margin-top: 15px;
    img {
      width: 100%;
    }
  }
}

.showing {
  left: 0px;
}
