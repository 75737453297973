// paleta 1
// :root {
//   // RGB codes
//   --main-1: 153, 51, 51;
//   --main-2: 204, 37, 37;
//   --main-3: 250, 11, 11;
//   --white: 255, 255, 255;
//   --gray-1: 204, 204, 204;
//   --black: 0, 0, 0;
//   // color definition
//   --color-main-1: rgb(var(--main-1));
//   --color-main-2: rgb(var(--main-2));
//   --color-main-3: rgb(var(--main-3));
//   --color-white: rgb(var(--white));
//   --color-gray-1: rgb(var(--gray-1));
//   --color-black: rgb(var(--black));
// }

// paleta 2
:root {
  // RGB codes
  --main-1: 42, 59, 142;
  --main-2: 68, 84, 156;
  --main-3: 107, 120, 178;
  --main-4: 49, 63, 120;
  --white: 255, 255, 255;
  --gray-1: 204, 204, 204;
  --gray-2: 133, 145, 192;
  --gray-3: 159, 168, 205;
  --black: 0, 0, 0;
  --dark: 38, 42, 105;
  --dark-2: #15203f;
  // color definition
  --color-main-1: rgb(var(--main-1));
  --color-main-2: rgb(var(--main-2));
  --color-main-3: rgb(var(--main-3));
  --color-main-4: rgb(var(--main-4));
  --color-white: rgb(var(--white));
  --color-gray-1: rgb(var(--gray-1));
  --color-gray-2: rgb(var(--gray-2));
  --color-gray-3: rgb(var(--gray-3));
  --color-black: rgb(var(--black));
  --color-dark: rgb(var(--dark));
  // --color-dark-2: rgb(var(--dark));
  // hex
  --new-main-1: 0, 193, 255;
  --new-main-2: 30, 47, 128;
  --new-main-3: 21, 31, 66;
  // color definition
  --new-color-1: rgb(var(--new-main-1));
  --new-color-2: rgb(var(--new-main-2));
  --new-color-3: rgb(var(--new-main-3));
  // gris
  --landing-gris-1: #5b6686;
  --landing-gris-2: #3a4562;
  // espacios
  --header-space: 9vh;
  --header-padding: 8px;
  --header-total: 9vh;
  --general-blue: rgb(49, 63, 120);
}
